import api from './api';

// 商圈列表
export const $sg_tradingAreaPage = (params) => api.get('/sssk/api/platform/biz/platform/tradingArea/tradingAreaPage', params);
// 删除商圈
// export const $sg_delTradingArea = (params) => api.post(`/sssk/api/platform/biz/platform/tradingArea/delTradingArea?tradingAreaId=${params}`, '', {
//   headers: {
//     'Content-Type': 'application/x-www-form-urlencoded',
//   },
// });
// 新增商圈信息
export const $sg_addTradingArea = (params) => api.post('/sssk/api/platform/biz/platform/tradingArea/addTradingArea', params);
// 修改商圈信息
export const $sg_updTradingArea = (params) => api.post('/sssk/api/platform/biz/platform/tradingArea/updTradingArea', params);
// 商圈详情
export const $sg_tradingAreaId = (params) => api.get(`/sssk/api/platform/biz/platform/tradingArea/getTradingArea?tradingAreaId=${params}`);
// 删除商圈
export const $sg_delTradingArea = (params) => api.post(`/sssk/api/platform/biz/platform/tradingArea/delTradingArea/${params}`, params);
// 骑手申请列表
export const $sg_riderApplyPage = (params) => api.get('/sssk/api/platform/biz/platform/riderManageArea/riderApplyPage', params);
// 骑手申请详情
export const $sg_riderApplyDetails = (params) => api.get(`/sssk/api/platform/biz/platform/riderManageArea/riderApplyDetails?riderApplyId=${params}`);
// 代理商列表
export const $sg_getTradingArea = (params) => api.get('/sssk/api/platform/biz/platform/tradingArea/agentV2VoPage', params);
// 商圈商户列表
export const $sg_tradingAreaShoppage = (params) => api.get('/sssk/api/platform/biz/tradingAreaShop/page', params);
// 移出
export const $sg_tradingAreaShopdelete = (params) => api.delete('/sssk/api/platform/biz/tradingAreaShop/delete', params);
// 新增商户
export const $sg_tradingAreaShopall = (params) => api.get('/sssk/api/platform/biz/tradingAreaShop/all', params);
// 添加商户
export const $sg_addTradingAreaShop = (params) => api.post('/sssk/api/platform/biz/tradingAreaShop/addTradingAreaShop', params);
// 获得商圈已选分类组件
export const $sg_getCategory = (params) => api.get('/sssk/api/platform/biz/tradingAreaShop/getCategory', params);
// 批量修改分类
export const $sg_updateCategoryBatch = (params) => api.post('/sssk/api/platform/biz/tradingAreaShop/updateCategoryBatch', params);
