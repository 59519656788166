<template>
  <div class="groupBuyingList">
    <div class="flexbox flex-lr flex-align-center">
      <el-input v-model="applyRiderForm.agentOrApplyName" placeholder="代理人/申请人姓名" style="width:200px;" clearable></el-input>
      <el-input v-model="applyRiderForm.tradingAreaName" placeholder="学校名称" style="width:200px;margin-left:20px;" clearable></el-input>
      <el-input v-model="applyRiderForm.applyerPhone" placeholder="手机号" style="width:200px;margin-left:20px;" class="mr20" clearable>
      </el-input>

    <el-date-picker
    class="mr20"
      :clearable="false"
      v-model="applyTime"
      type="daterange"
      range-separator="至"
      value-format="yyyy-MM-dd"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>
      <el-button type="primary" @click="search">筛选</el-button>
      <el-button type="info" @click="clear">清空</el-button>
    </div>
    <div class="tableBox">
      <el-table :data="applyRiderTable" style="width: 100%" border>
        <el-table-column prop="createTime" label="申请时间" align="center"></el-table-column>
        <el-table-column prop="agentName" label="归属代理商" align="center"></el-table-column>
        <el-table-column prop="agentPhone" label="代理商联系方式" align="center"></el-table-column>
        <el-table-column prop="tradingAreaName" label="归属学校" align="center"></el-table-column>
        <el-table-column prop="applyName" label="真实姓名" align="center"></el-table-column>
        <el-table-column prop="applyerGender" label="性别" align="center">
          <template slot-scope="scope">
          <span>{{scope.row.applyerGender== 1 ? '男' :'女'}}</span>
        </template>
        </el-table-column>
        <el-table-column prop="applyerPhone" label="手机号码" align="center"></el-table-column>
        <el-table-column prop="riderApplyExtInfo.professionalClass" label="专业班级" align="center">
        </el-table-column>
        <el-table-column prop="riderApplyExtInfo.grade" label="年级" align="center">
        </el-table-column>
        <el-table-column prop="riderApplyExtInfo.dormitoryArea" label="宿舍位置" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.riderApplyExtInfo.dormitoryArea}}{{scope.row.riderApplyExtInfo.dormitoryNumber}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="riderApplyExtInfo.dormitoryNumber" label="宿舍号码" align="center">
        </el-table-column> -->
        <!-- <el-table-column prop="name" label="状态" align="center">
        </el-table-column> -->
        <el-table-column prop="name" label="操作" align="center">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="openApplyDialog(scope.row,1)">去处理</el-button> -->
            <el-button type="text" @click="openApplyDialog(scope.row.riderApplyId)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <el-dialog width="600px" :visible='showApplyRiderDialog' center @close="showApplyRiderDialog=false">
      <span slot='title' style="font-size:18px;font-weight:bold;">
        申请人详情
      </span>
      <p class="mt10 mb10"><span class="distri-layout-mark"></span><span class="distri-layout-title">基础信息</span></p>
      <div class="basicInfo">
        <div class="leftContent">
          <p>申请时间：{{tableData.createTime||'-'}}</p>
          <p>归属代理商：{{tableData.agentName ||'-'}}</p>
          <p>代理商联系电话：{{tableData.agentPhone ||'-'}}</p>
          <p>归属学校：{{tableData.tradingAreaName ||'-'}}</p>
          <p>宿舍位置：{{tableDatawithin.dormitoryArea+tableDatawithin.dormitoryNumber ||'-'}}</p>
        </div>
        <div class="rightContent">
          <p>真实姓名：{{tableData.applyName ||'-'}}</p>
          <p>性别：{{tableData.applyerGender== 1 ? '男' :'女'}}</p>
          <p>手机号：{{tableData.applyerPhone ||'-'}}</p>
          <p>专业班级：{{tableDatawithin.professionalClass ||'-'}}</p>
          <p>年级：{{tableDatawithin.grade ||'-'}}</p>
        </div>
      </div>
      <p class="mt10 mb10"><span class="distri-layout-mark"></span><span class="distri-layout-title">其他信息</span></p>
      <div class="otherInfo">
        <p><span>有晚自习（是否每天都有在上晚自习）</span>
        <span v-if="tableDatawithin.isLateSelfStudy"><i class="el-icon-circle-check" style="font-size: 25px; color:red;"></i></span>
        <span v-else><i class="el-icon-circle-close" style="font-size: 25px;"></i></span>
        </p>
        <p><span>长期兼职（大于两个月为长期）</span>
        <span v-if="tableDatawithin.isLongTermPartTime"><i class="el-icon-circle-check" style="font-size: 25px; color:red;"></i></span>
        <span v-else><i class="el-icon-circle-close" style="font-size: 25px;"></i></span>
        </p>
        <p><span>周末在校（周末是否能在校）</span>
        <span v-if="tableDatawithin.isSchoolOnWeekends"><i class="el-icon-circle-check" style="font-size: 25px; color:red;"></i></span>
        <span v-else><i class="el-icon-circle-close" style="font-size: 25px;"></i></span>
        </p>
      </div>
      <p class="mt10 mb10"><span class="distri-layout-mark"></span><span class="distri-layout-title">代理商处理情况</span></p>
      <div class="remarks">
        <p>处理状态:{{tableData.auditState == 0 ? "审核中" : (tableData.auditState == 1 ? "审核成功" :
          (tableData.auditState == 2 ? "审核成功" :'未填写'))}}</p>
        <p>备注:{{tableData.auditDesc}}</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  $sg_riderApplyPage,
  $sg_riderApplyDetails,
} from '@/api/schoolGroup';

export default {
  data() {
    return {
      tableData: {}, // 骑手详情
      tableDatawithin: {}, // 骑手详情
      applyTime: [], // 时间
      iconColor: '', // 图标颜色
      iconx: 'el-icon-circle-check', // 图标
      icons: 0, // 图标判断
      showApplyRiderDialog: false,
      totalPage: 1,
      currentPage: 1,
      applyRiderForm: {
        agentOrApplyName: '', // 代理商/申请人姓名
        applyerPhone: '', // 申请人电话
        tradingAreaName: '', // 归属学校
      },
      applyRiderTable: [],
      riderInfoForm: 1,
    };
  },
  created() {
    this.getApplyRiderData();
    // if (this.icons) {
    //   this.iconColor = 'red';
    // } else {
    //   this.iconx = 'el-icon-circle-close';
    // }
  },
  methods: {
    // 打开骑手申请弹窗
    openApplyDialog(id) {
    //   this.riderInfoForm = row;
      $sg_riderApplyDetails(id).then((res) => {
        this.tableData = res;
        this.tableDatawithin = this.tableData.riderApplyExtInfo;
        this.showApplyRiderDialog = true;
      }).catch(() => {
        this.$message.error('查无此商圈！');
      });
    },

    // 筛选
    search() {
      this.currentPage = 1;
      this.getApplyRiderData();
    },
    // 清空
    clear() {
      this.applyRiderForm = {
        agentOrApplyName: '', // 代理商/申请人姓名
        applyerPhone: '', // 申请人电话
        tradingAreaName: '', // 归属学校
      };
      this.applyTime = [];
      this.currentPage = 1;
      this.getApplyRiderData();
    },
    // 请求列表数据
    getApplyRiderData() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 15,
        ...this.applyRiderForm,
      };
      params.beginTime = this.applyTime[0];
      params.endTime = this.applyTime[1];
      $sg_riderApplyPage(params).then((res) => {
        this.applyRiderTable = res.records;
        this.totalPage = res.pages;
      });
    },

    // 翻页
    pageChange() {
      this.getApplyRiderData();
    },
  },
};
</script>
<style lang="scss" scoped>
.groupBuyingList {
  width: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .tips {
    width: 100%;
    margin-top: 40px;
    font-size: 14px;
    color: #666;
  }
  .tableBox {
    width: 100%;
    margin-top: 10px;
  }
  .el-table {
    min-height: 580px;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
  .distri-layout-mark {
    width: 3px;
    display: inline-block;
    height: 19px;
    background: #ef3f46;
    opacity: 1;
  }
  .distri-layout-title {
    padding: 0 10px;
    vertical-align: 4px;
    font-size: 16px;
    color: #4d4d4d;
  }
  .basicInfo {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    p {
      margin-bottom: 5px;
    }
  }
  .otherInfo {
    padding-left: 10px;
    padding-right: 10px;
    > p {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
    }
    .el-checkbox {
      /deep/.el-checkbox__inner {
        border-radius: 50%;
      }
    }
  }
  .remarks {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>

